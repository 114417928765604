@import 'variables';

.user-role-type, .user-role-type-lable {
    cursor: pointer;
}

.header-login {
    h1 {
        font-weight: bold;
    }
}

.color-default {
    color: $default-color;
}

.radio-item {
    display: inline-block;
    position: relative;
    padding: 0 6px;
    margin: 10px 0 0;
    color: #000;
    label {
        &:before {
            content: " ";
            display: inline-block;
            position: relative;
            top: 5px;
            margin: 0 5px 0 0;
            width: 20px;
            height: 20px;
            border-radius: 11px;
            border: 2px solid #000;
            background-color: transparent;
        }
    }
    input {
        &[type='radio'] {
            display: none;
            &:checked {
                & + label {
                    &:before {
                        border: 2px solid $default-color;
                    }
                    &:after {
                        border-radius: 11px;
                        width: 12px;
                        height: 12px;
                        position: absolute;
                        top: 9px;
                        left: 10px;
                        content: " ";
                        display: block;
                        background: $default-color;
                    }
                }
            }
        }
    }
}


.social-buttons {
    .btn {
        display: inline-block;
        padding: 6px 12px !important;
        margin-bottom: 0;
        font-size: 14px;
        font-weight: normal;
        line-height: 1.42857143;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-image: none;
        border: 1px solid transparent;
        border-radius: 4px;
    }

    .btn-block {
        display: block;
        width: 100%;
    }

    .btn-social {
        position: relative;
        padding-left: 44px !important;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #fff;
        border-color: rgba(0,0,0,0.2);
        &>:first-child {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 32px;
            line-height: 34px;
            font-size: 1.6em;
            text-align: center;
            border-right: 1px solid rgba(0,0,0,0.2);
        }
    }

    .btn-google {
        background-color: #dd4b39;
        &:hover {
            color: #fff;
            background-color: #c23321;
            border-color: rgba(0,0,0,0.2);
        }
    }

    .btn-facebook{
        background-color: #3b5998;
        &:hover {
            color: #fff;
            background-color: #2d4373;
            border-color: rgba(0,0,0,0.2);
        }
    }

    .btn-zalo {
        color: #0849ef;
        background-color: #fff;
        .fa-zalo {
            font-size: 13px;
        }
        &:hover {
            background-color: #dddddd70;
        }
    }

    .btn-apple {
        color: #000;
        background-color: #fff;
        &:hover {
            background-color: #dddddd70;
        }
    }

    .policy {
        color: #3490dc;
        text-decoration: revert;
        background-color: transparent;
        display: block;
        text-align: center;
        margin-top: 44px;
        &:hover {
            color: #1d68a7;
        }
    }
}
.footer {
    box-shadow: 4px 0 20px -5px rgb(0 0 0 / 20%);
}
